const Thankyou = () => {
  return (
    <div>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Architecto
      aliquam blanditiis molestiae dignissimos rerum laudantium cupiditate
      eligendi voluptatem dicta eius provident, iure error perspiciatis placeat
      veritatis illo ratione iusto? Dignissimos asperiores aut maxime cupiditate
      ab. Suscipit delectus quia ducimus explicabo ullam, molestiae repellendus,
      a laboriosam corrupti vero mollitia, temporibus maxime voluptatum? Ut
      perspiciatis et quisquam pariatur laborum tempora necessitatibus
      consequatur, sit, repellendus quidem eligendi temporibus repellat deleniti
      omnis? Iusto consequuntur commodi nisi praesentium nobis sint maiores
      architecto harum illo odit. Quisquam nobis nesciunt soluta, ea excepturi
      alias perspiciatis officiis dignissimos facere ut velit mollitia? Esse
      modi quam quas nisi molestiae.
    </div>
  );
};

export default Thankyou;
