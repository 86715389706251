import ImageOne from "../images/ImageOne.jpg";
import ImageTwo from "../images/ImageTwo.jpg";
import ImageThree from "../images/ImageThree.jpg";
import ImageFour from "../images/ImageFour.jpg";

export const SliderData = [
  {
    title: "Deluxe Ajgeraj",
    subtitle: "With us you have a colorful and clean home",
    image: ImageOne,
    alt: "",
  },
  {
    title: "Deluxe Ajgeraj",
    subtitle: "With us you have a colorful and clean home",
    image: ImageTwo,
    alt: "",
  },
  {
    title: "Deluxe Ajgeraj",
    subtitle: "With us you have a colorful and clean home",
    image: ImageThree,
    alt: "",
  },
  {
    title: "Deluxe Ajgeraj",
    subtitle: "With us you have a colorful and clean home",
    image: ImageFour,
    alt: "",
  },
];
